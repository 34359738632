import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import "../css/default.css";

const Question3 = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [viewA1, setViewA1] = useState("none");
	const [viewA2, setViewA2] = useState("none");
	const [viewA3, setViewA3] = useState("none");
	const [viewA4, setViewA4] = useState("none");

	useEffect(() => {
		const qs = queryString.parse(location.search);

		if (!qs.i || !qs.n || !qs.q1 || !qs.q2) {
			alert("잘못된 접근입니다.");
			navigate("/");
		}
	}, []);

	function select(value) {
		const qs = queryString.parse(location.search);

		if (value === 1) {
			setViewA1("");
		} else if (value === 2) {
			setViewA2("");
		} else if (value === 3) {
			setViewA3("");
		} else {
			setViewA4("");
		}

		setTimeout(() => {
			navigate(
				`/q4?i=${qs.i}&n=${qs.n}&q1=${qs.q1}&q2=${qs.q2}&q3=${value}`,
			);
		}, 100);
	}

	return (
		<>
			<div className="container">
				<div className="question question3">
					<header>
						<div className="question_back fadein">
							<img
								src="img/question/back.png"
								alt="back"
								className="pointer question_back_btn"
								onClick={() => navigate(-1)}
							></img>
						</div>
					</header>
					<main className="center_content fadein">
						<div>
							<div className="center_content">
								<p className="question_text_question">
									무인도에 간다면<br></br>누구와 함께 갈까?
								</p>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA1}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(1)}
										>
											친구
										</p>
									</div>
									<img
										src="img/question/line.png"
										alt="line"
										className="question_img_line"
									></img>
								</div>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA2}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(2)}
										>
											가족
										</p>
									</div>
									<div>
										<img
											src="img/question/line.png"
											alt="line"
											className="question_img_line"
										></img>
									</div>
								</div>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA3}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(3)}
										>
											애인
										</p>
									</div>
									<div>
										<img
											src="img/question/line.png"
											alt="line"
											className="question_img_line"
										></img>
									</div>
								</div>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA4}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(4)}
										>
											반려동물
										</p>
									</div>
									<div>
										<img
											src="img/question/line.png"
											alt="line"
											className="question_img_line"
										></img>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	);
};

export default Question3;

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import queryString from "query-string";

import "../css/default.css";

const Question7 = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [viewA1, setViewA1] = useState("none");
	const [viewA2, setViewA2] = useState("none");
	const [viewA3, setViewA3] = useState("none");
	const [viewA4, setViewA4] = useState("none");

	useEffect(() => {
		const qs = queryString.parse(location.search);

		if (
			!qs.i ||
			!qs.n ||
			!qs.q1 ||
			!qs.q2 ||
			!qs.q3 ||
			!qs.q4 ||
			!qs.q5 ||
			!qs.q6
		) {
			alert("잘못된 접근입니다.");
			navigate("/");
		}
	}, []);

	function select(value) {
		const qs = queryString.parse(location.search);

		if (value === 1) {
			setViewA1("");
		} else if (value === 2) {
			setViewA2("");
		} else if (value === 3) {
			setViewA3("");
		} else {
			setViewA4("");
		}

		setTimeout(() => {
			navigate(
				`/q8?i=${qs.i}&n=${qs.n}&q1=${qs.q1}&q2=${qs.q2}&q3=${qs.q3}&q4=${qs.q4}&q5=${qs.q5}&q6=${qs.q6}&q7=${value}`,
			);
		}, 100);
	}

	return (
		<>
			<div className="container">
				<div className="question question7">
					<header>
						<div className="question_back fadein">
							<img
								src="img/question/back.png"
								alt="back"
								className="pointer question_back_btn"
								onClick={() => navigate(-1)}
							></img>
						</div>
					</header>
					<main className="center_content fadein">
						<div>
							<div className="center_content">
								<p className="question_text_question">
									가구를 고를 때<br></br>가장 신경 쓰는 점은?
								</p>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA1}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(1)}
										>
											편안한 느낌
										</p>
									</div>
									<img
										src="img/question/line.png"
										alt="line"
										className="question_img_line question_img_line1"
									></img>
								</div>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA2}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(2)}
										>
											보기 좋은 디자인
										</p>
									</div>
									<div>
										<img
											src="img/question/line.png"
											alt="line"
											className="question_img_line question_img_line2"
										></img>
									</div>
								</div>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA3}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(3)}
										>
											새로운 신상
										</p>
									</div>
									<div>
										<img
											src="img/question/line.png"
											alt="line"
											className="question_img_line question_img_line3"
										></img>
									</div>
								</div>
							</div>
							<div className="center_content question_radio">
								<div>
									<div className="center_content question_content">
										<img
											src="img/question/checked.png"
											alt="checked"
											className={`question_img_checked ${viewA4}`}
										></img>
										<p
											className="question_content_text pointer"
											onClick={() => select(4)}
										>
											튼튼한 견고성
										</p>
									</div>
									<div>
										<img
											src="img/question/line.png"
											alt="line"
											className="question_img_line question_img_line4"
										></img>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</>
	);
};

export default Question7;

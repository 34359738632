import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";

import queryString from "query-string";

const Analysis = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [name, setName] = useState("");
	const [analysisTextNameStyle, setAnalysisTextNameStyle] = useState({
		fontSize: "22.93px",
	});

	const [viewCapsule1, setViewCapsule1] = useState("hidden");
	const [viewCapsule2, setViewCapsule2] = useState("hidden");
	const [viewCapsule3, setViewCapsule3] = useState("hidden");
	const [viewCapsule4, setViewCapsule4] = useState("hidden");

	useEffect(() => {
		const qs = queryString.parse(location.search);

		if (
			!qs.i ||
			!qs.n ||
			!qs.q1 ||
			!qs.q2 ||
			!qs.q3 ||
			!qs.q4 ||
			!qs.q5 ||
			!qs.q6 ||
			!qs.q7 ||
			!qs.q8
		) {
			alert("잘못된 접근입니다.");
			navigate("/");
		}

		setName(qs.n);
		if (window.innerWidth > 1024) {
			if (qs.n.length === 6) {
				setAnalysisTextNameStyle({
					fontSize: "16.8px",
					marginTop: "4px",
				});
			} else if (qs.n.length === 5) {
				setAnalysisTextNameStyle({
					fontSize: "20.1px",
					marginTop: "2px",
				});
			}
		} else if (window.innerWidth <= 1024 && window.innerWidth > 480) {
			if (qs.n.length === 6) {
				setAnalysisTextNameStyle({
					fontSize: "calc(16.8/390*100vw)",
					marginTop: "calc(4/390*100vw)",
				});
			} else if (qs.n.length === 5) {
				setAnalysisTextNameStyle({
					fontSize: "calc(20.1/390*100vw)",
					marginTop: "calc(2/390*100vw)",
				});
			} else {
				setAnalysisTextNameStyle({
					fontSize: "calc(22.93/390*100vw)",
				});
			}
		} else {
			if (qs.n.length === 6) {
				setAnalysisTextNameStyle({
					fontSize: "calc(16.8/670*var(--ih, 100vh))",
					marginTop: "calc(4/670*var(--ih, 100vh))",
				});
			} else if (qs.n.length === 5) {
				setAnalysisTextNameStyle({
					fontSize: "calc(20.1/670*var(--ih, 100vh))",
					marginTop: "calc(2/670*var(--ih, 100vh))",
				});
			} else {
				setAnalysisTextNameStyle({
					fontSize: "calc(22.93/670*var(--ih, 100vh))",
				});
			}
		}

		moveResult();
	}, []);

	function analysis() {
		const weight = [
			0.0001, 0.00001, 0.1, 0.01, 0.001, 0.000001, 0.0000001, 0.00000001,
		];
		const resultMap = {
			americanWitBier: [4, 2, 1, 2, 1, 1, 1, 2], // gramNotebook
			paleAle: [3, 4, 1, 3, 3, 3, 2, 4], // duobo
			dancingLager: [3, 2, 4, 3, 1, 4, 2, 2], // duobo
			californiaCommon: [1, 1, 2, 2, 2, 2, 4, 2], // shoeCase

			bavarianWeissbier: [3, 3, 4, 3, 3, 2, 1, 4], // styler
			pilsner: [2, 1, 2, 4, 3, 1, 2, 4], // standByMeGo
			sopoongLager: [1, 4, 4, 1, 4, 2, 2, 1], // aeroFurniture
			redAle: [3, 2, 1, 2, 1, 4, 1, 1], // aeroFurniture

			citrusAle: [4, 2, 3, 1, 2, 4, 3, 3], // wineSeller
			ipa: [4, 4, 1, 2, 4, 3, 4, 1], // tiiunMini
			spicyIpa: [1, 1, 2, 3, 3, 2, 3, 3], // puriCare
			wheat: [2, 3, 4, 1, 4, 3, 1, 4], // puriCare

			sessionIpa: [2, 3, 3, 1, 4, 3, 3, 3], // homebrew
			summerAle: [4, 3, 3, 4, 2, 4, 3, 3], // homebrew
			stout: [1, 4, 2, 4, 1, 1, 4, 1], // healingMe
			darkAle: [2, 1, 3, 4, 2, 1, 4, 2], // healingMe
		};

		let scoreMap = {
			americanWitBier: 0,
			citrusAle: 0,
			ipa: 0,
			spicyIpa: 0,
			sessionIpa: 0,
			redAle: 0,
			californiaCommon: 0,
			stout: 0,
			darkAle: 0,
			wheat: 0,
			bavarianWeissbier: 0,
			paleAle: 0,
			summerAle: 0,
			pilsner: 0,
			dancingLager: 0,
			sopoongLager: 0,
		};

		const qs = queryString.parse(location.search);
		const result = [
			Number(qs.q1),
			Number(qs.q2),
			Number(qs.q3),
			Number(qs.q4),
			Number(qs.q5),
			Number(qs.q6),
			Number(qs.q7),
			Number(qs.q8),
		];

		for (let i = 0; i < 8; i++) {
			for (const [key, value] of Object.entries(resultMap)) {
				if (value[i] === result[i]) {
					scoreMap[key] = scoreMap[key] + 1 + weight[i];
				}
			}
		}

		const scores = Object.values(scoreMap);
		const maxScore = Math.max(...scores);

		for (const [key, value] of Object.entries(scoreMap)) {
			if (value === maxScore) {
				return key;
			}
		}

		return null;
	}

	function moveResult() {
		const qs = queryString.parse(location.search);

		setTimeout(() => {
			setViewCapsule1("");
		}, 600);

		setTimeout(() => {
			setViewCapsule2("");
		}, 1200);

		setTimeout(() => {
			setViewCapsule3("");
		}, 1800);

		setTimeout(() => {
			setViewCapsule4("");
		}, 2400);

		setTimeout(async () => {
			const result = analysis();

			await API.graphql({
				query: mutations.updateCustomer,
				variables: {
					input: {
						id: qs.i,
						mbti: result,
					},
				},
			});

			if (result === "americanWitBier") {
				navigate(`/awb?n=${qs.n}`);
			} else if (result === "bavarianWeissbier") {
				navigate(`/baw?n=${qs.n}`);
			} else if (result === "californiaCommon") {
				navigate(`/cac?n=${qs.n}`);
			} else if (result === "citrusAle") {
				navigate(`/cia?n=${qs.n}`);
			} else if (result === "dancingLager") {
				navigate(`/dal?n=${qs.n}`);
			} else if (result === "darkAle") {
				navigate(`/daa?n=${qs.n}`);
			} else if (result === "ipa") {
				navigate(`/ipa?n=${qs.n}`);
			} else if (result === "paleAle") {
				navigate(`/paa?n=${qs.n}`);
			} else if (result === "pilsner") {
				navigate(`/pil?n=${qs.n}`);
			} else if (result === "redAle") {
				navigate(`/rea?n=${qs.n}`);
			} else if (result === "sessionIpa") {
				navigate(`/sei?n=${qs.n}`);
			} else if (result === "sopoongLager") {
				navigate(`/sol?n=${qs.n}`);
			} else if (result === "spicyIpa") {
				navigate(`/spi?n=${qs.n}`);
			} else if (result === "stout") {
				navigate(`/sto?n=${qs.n}`);
			} else if (result === "summerAle") {
				navigate(`/sua?n=${qs.n}`);
			} else if (result === "wheat") {
				navigate(`/whe?n=${qs.n}`);
			} else {
				alert("문제가 발생했습니다. 잠시 후 다시 시도해 주세요.");
				navigate("/");
			}
		}, 2500);
	}

	return (
		<>
			<div className="container">
				<div className="analysis">
					<div className="center_content fadein">
						<img
							src="img/analysis/haus.png"
							alt="haus"
							className="analysis_img_haus"
						></img>
					</div>
					<div className="center_content fadein">
						<div>
							<div className="center_content">
								<div>
									<p className="analysis_text_line1">
										취향 테스트가 곧 시작됩니다.
									</p>
									<div className="center_content analysis_line2">
										<div className="analysis_name_box">
											<div className="center_content">
												<span
													className="analysis_text_name"
													style={
														analysisTextNameStyle
													}
												>
													{name}
												</span>
											</div>
											<img
												src="img/analysis/line.png"
												alt="line"
												className="analysis_img_line"
											></img>
										</div>
										<div className="analysis_text_line2">
											님의 가전 취향은?
										</div>
									</div>
								</div>
							</div>
							<div className="center_content">
								<div>
									<img
										src="img/analysis/capsule.png"
										alt="analysis1"
										className={`analysis_img_capsule ${viewCapsule1}`}
									></img>
									<img
										src="img/analysis/capsule.png"
										alt="analysis2"
										className={`analysis_img_capsule ${viewCapsule2}`}
									></img>
									<img
										src="img/analysis/capsule.png"
										alt="analysis3"
										className={`analysis_img_capsule ${viewCapsule3}`}
									></img>
									<img
										src="img/analysis/capsule.png"
										alt="analysis3"
										className={`analysis_img_capsule ${viewCapsule4}`}
									></img>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Analysis;

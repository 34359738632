/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_appsync_graphqlEndpoint": "https://lwwuoue62jca7ivik4uvuyfisa.appsync-api.ap-northeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-274ahiwjmfaidjnjxdfugt2nxm",
    "aws_cloud_logic_custom": [
        {
            "name": "lghbCustomer",
            "endpoint": "https://bq4juwo4j7.execute-api.ap-northeast-2.amazonaws.com/prod",
            "region": "ap-northeast-2"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-2:e3734789-b162-44f7-bf27-6ce709e9cd25",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_oIDx9JHFW",
    "aws_user_pools_web_client_id": "3u5gbosv8n0vfr3mmr3mgfdrv1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lghbflagshipstorage161855-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-2"
};


export default awsmobile;

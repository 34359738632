import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import crypto from "crypto-js";

import "../css/default.css";

const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

const initialState = {
	id: null,
	type: "Customer",
	name: null,
	gender: null,
	age: 0,
	phoneNumber: null,
	number: 0,
	marketing: "N",
};

const Info = () => {
	const nameInput = useRef(null);
	const phoneNumberInput = useRef(null);

	const navigate = useNavigate();

	const [viewMale, setViewMale] = useState("hidden");
	const [viewFemale, setViewFemale] = useState("hidden");
	const [viewPopup, setViewPopup] = useState("hidden");

	const [view20, setView20] = useState("hidden");
	const [view30, setView30] = useState("hidden");
	const [view40, setView40] = useState("hidden");
	const [view50, setView50] = useState("hidden");
	const [view60, setView60] = useState("hidden");

	const [viewTotalAgree, setViewTotalAgree] = useState("hidden");
	const [viewUseAgree, setViewUseAgree] = useState("hidden");
	const [viewUseDisagree, setViewUseDisagree] = useState("");
	const [viewMarketingAgree, setViewMarketingAgree] = useState("hidden");
	const [viewMarketingDisagree, setViewMarketingDisagree] = useState("");
	const [viewOfferAgree, setViewOfferAgree] = useState("hidden");
	const [viewOfferDisagree, setViewOfferDisagree] = useState("");

	const [viewRegisterEvent, setViewRegisterEvent] = useState("");

	const [formState, setFormState] = useState(initialState);

	function select(type, value) {
		console.log(formState);
		if (type === "gender") {
			setInput(type, value);

			if (value === "male") {
				setViewMale("");
				setViewFemale("hidden");
			} else {
				setViewMale("hidden");
				setViewFemale("");
			}
		} else if (type === "age") {
			setInput(type, value);

			if (value === 20) {
				setView20("");
				setView30("hidden");
				setView40("hidden");
				setView50("hidden");
				setView60("hidden");
			} else if (value === 30) {
				setView20("hidden");
				setView30("");
				setView40("hidden");
				setView50("hidden");
				setView60("hidden");
			} else if (value === 40) {
				setView20("hidden");
				setView30("hidden");
				setView40("");
				setView50("hidden");
				setView60("hidden");
			} else if (value === 50) {
				setView20("hidden");
				setView30("hidden");
				setView40("hidden");
				setView50("");
				setView60("hidden");
			} else {
				setView20("hidden");
				setView30("hidden");
				setView40("hidden");
				setView50("hidden");
				setView60("");
			}
		} else if (type === "totalAgree") {
			if (viewTotalAgree === "") {
				setViewTotalAgree("hidden");

				setViewUseAgree("hidden");
				setViewUseDisagree("");
				setViewOfferAgree("hidden");
				setViewOfferDisagree("");
				setViewMarketingAgree("hidden");
				setViewMarketingDisagree("");

				setInput("marketing", "N");
			} else {
				setViewTotalAgree("");

				setViewUseAgree("");
				setViewUseDisagree("hidden");
				setViewOfferAgree("");
				setViewOfferDisagree("hidden");
				setViewMarketingAgree("");
				setViewMarketingDisagree("hidden");

				setInput("marketing", "Y");
			}
		} else if (type === "useAgree") {
			if (value === "Y") {
				setViewUseAgree("");
				setViewUseDisagree("hidden");

				if ((viewMarketingAgree === "") & (viewOfferAgree === "")) {
					setViewTotalAgree("");
				}
			} else {
				setViewUseAgree("hidden");
				setViewUseDisagree("");

				setViewTotalAgree("hidden");
			}
		} else if (type === "marketingAgree") {
			if (value === "Y") {
				setViewMarketingAgree("");
				setViewMarketingDisagree("hidden");
				setInput("marketing", "Y");

				if ((viewUseAgree === "") & (viewOfferAgree === "")) {
					setViewTotalAgree("");
				}
			} else {
				setViewMarketingAgree("hidden");
				setViewMarketingDisagree("");
				setInput("marketing", "N");

				setViewTotalAgree("hidden");
			}
		} else if (type === "offerAgree") {
			if (value === "Y") {
				setViewOfferAgree("");
				setViewOfferDisagree("hidden");

				if ((viewMarketingAgree === "") & (viewUseAgree === "")) {
					setViewTotalAgree("");
				}
			} else {
				setViewOfferAgree("hidden");
				setViewOfferDisagree("");

				setViewTotalAgree("hidden");
			}
		}
	}

	function submit() {
		setViewRegisterEvent("_event");
		setTimeout(() => setViewRegisterEvent(""), 100);

		if (!formState.name) {
			alert("이름을 작성해야 합니다.");
			nameInput.current.focus();
			return;
		}

		if (!formState.phoneNumber) {
			alert("연락처를 작성해야 합니다.");
			phoneNumberInput.current.focus();
			return;
		}

		if (!regPhone.test(formState.phoneNumber)) {
			alert("올바른 연락처(01x-xxxx-xxxx)를 작성해야 합니다.");
			phoneNumberInput.current.focus();
			return;
		}

		if (!formState.gender) {
			alert("성별을 선택해야 합니다.");
			window.scrollTo(0, 0);
			return;
		}

		if (!formState.age) {
			alert("연령대를 선택해야 합니다.");
			window.scrollTo(0, 0);
			return;
		}

		if (viewUseAgree === "hidden") {
			alert("개인정보 수집 이용에 동의하셔야 합니다.");
			setViewPopup("");
			window.scrollTo(0, 0);
			return;
		}

		if (viewOfferAgree === "hidden") {
			alert("개인정보 제 3자 제공에 동의하셔야 합니다.");
			setViewPopup("");
			window.scrollTo(0, 0);
			return;
		}

		let cipherP = crypto.AES.encrypt(
			JSON.stringify(formState.phoneNumber),
			"secret 12345",
		).toString();

		let encData = crypto.enc.Base64.stringify(
			crypto.enc.Utf8.parse(cipherP),
		);

		setViewRegisterEvent("_event");

		navigate(
			`/loading?n=${formState.name}&g=${formState.gender}&a=${formState.age}&p=${encData}&m=${formState.marketing}`,
		);

		return;
	}

	function setInput(key, value) {
		setFormState({ ...formState, [key]: value });
	}

	function hyphen(num) {
		return num
			.replace(/-/g, "")
			.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
	}

	function togglePopup(value) {
		setViewPopup(value);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className="container">
				<div className="info">
					<div className="center_content fadein">
						<div>
							<div className="left_content name_box">
								<div className="label_name">
									<span className="label_name_ko">이름</span>
									<span className="label_name_en">NAME</span>
								</div>
								<div>
									<input
										type="text"
										className="input_name"
										maxLength={6}
										value={formState.name}
										onChange={(e) =>
											setInput("name", e.target.value)
										}
										ref={nameInput}
									></input>
									<img
										src="img/info/line.png"
										alt="line"
										className="info_img_name_line"
									></img>
								</div>
							</div>
							<div className="left_content mobile_box">
								<div className="label_mobile">
									<span className="label_mobile_ko">
										연락처
									</span>
									<span className="label_mobile_en">
										MOBILE
									</span>
								</div>
								<div>
									<input
										type="tel"
										className="input_mobile"
										value={formState.phoneNumber}
										onChange={(e) =>
											setInput(
												"phoneNumber",
												hyphen(e.target.value),
											)
										}
										ref={phoneNumberInput}
									></input>
									<img
										src="img/info/line.png"
										alt="line"
										className="info_img_mobile_line"
									></img>
									<img
										src="img/info/notice_text.png"
										alt="notice"
										className="info_img_mobile_notice_text"
									></img>
								</div>
							</div>
							<div className="left_content gender_box">
								<div className="label_gender">
									<span className="label_gender_ko">
										성별
									</span>
									<span className="label_gender_en">
										GENDER
									</span>
								</div>
								<div className="gender_radio">
									<img
										src="img/info/check_box.png"
										alt="check box"
										className="pointer info_img_check_box_male"
										onClick={() => {
											select("gender", "male");
										}}
									></img>
									<img
										src="img/info/checked.png"
										alt="checked"
										className={`info_img_checked_male ${viewMale}`}
									></img>
									<span
										className={"info_text_male_ko pointer"}
										onClick={() => {
											select("gender", "male");
										}}
									>
										남
									</span>
									<span
										className="info_text_male_en pointer"
										onClick={() => {
											select("gender", "male");
										}}
									>
										MALE
									</span>
								</div>
								<div className="gender_radio">
									<img
										src="img/info/check_box.png"
										alt="check box"
										className="pointer info_img_check_box_female"
										onClick={() => {
											select("gender", "female");
										}}
									></img>
									<img
										src="img/info/checked.png"
										alt="checked"
										className={`info_img_checked_female ${viewFemale}`}
									></img>
									<span
										className="info_text_female_ko pointer"
										onClick={() => {
											select("gender", "female");
										}}
									>
										여
									</span>
									<span
										className="info_text_female_en pointer"
										onClick={() => {
											select("gender", "female");
										}}
									>
										FEMALE
									</span>
								</div>
							</div>
							<div className="left_content age_box">
								<div className="label_age">
									<span className="label_age_ko">연령대</span>
									<span className="label_age_en">AGE</span>
								</div>
								<div className="age_radio">
									<img
										src="img/info/check_box.png"
										alt="check box"
										className="pointer info_img_check_box_age"
										onClick={() => {
											select("age", 20);
										}}
									></img>
									<img
										src="img/info/checked.png"
										alt="checked"
										className={`info_img_checked_20 ${view20}`}
									></img>
									<span
										className="info_text_age pointer"
										onClick={() => {
											select("age", 20);
										}}
									>
										20
									</span>
								</div>
								<div className="age_radio">
									<img
										src="img/info/check_box.png"
										alt="check box"
										className="pointer info_img_check_box_age"
										onClick={() => {
											select("age", 30);
										}}
									></img>
									<img
										src="img/info/checked.png"
										alt="checked"
										className={`info_img_checked_30 ${view30}`}
									></img>
									<span
										className="info_text_age pointer"
										onClick={() => {
											select("age", 30);
										}}
									>
										30
									</span>
								</div>
								<div className="age_radio">
									<img
										src="img/info/check_box.png"
										alt="check box"
										className="pointer info_img_check_box_age"
										onClick={() => {
											select("age", 40);
										}}
									></img>
									<img
										src="img/info/checked.png"
										alt="checked"
										className={`info_img_checked_40 ${view40}`}
									></img>
									<span
										className="info_text_age pointer"
										onClick={() => {
											select("age", 40);
										}}
									>
										40
									</span>
								</div>
								<div className="age_radio">
									<img
										src="img/info/check_box.png"
										alt="check box"
										className="pointer info_img_check_box_age"
										onClick={() => {
											select("age", 50);
										}}
									></img>
									<img
										src="img/info/checked.png"
										alt="checked"
										className={`info_img_checked_50 ${view50}`}
									></img>
									<span
										className="info_text_age pointer"
										onClick={() => {
											select("age", 50);
										}}
									>
										50
									</span>
								</div>
								<div className="age_radio">
									<img
										src="img/info/check_box.png"
										alt="check box"
										className="pointer info_img_check_box_age"
										onClick={() => {
											select("age", 60);
										}}
									></img>
									<img
										src="img/info/checked.png"
										alt="checked"
										className={`info_img_checked_60 ${view60}`}
									></img>
									<span
										className="info_text_age pointer"
										onClick={() => {
											select("age", 60);
										}}
									>
										60
									</span>
								</div>
							</div>
							<div className="left_content bottom_content agree_box">
								<div className="label_agree">
									개인정보 수집 및 이용 / 수신 / 제공 모두
									동의
								</div>
								<div
									className="left_content bottom_content agree_checkbox_box pointer"
									onClick={() => {
										select("totalAgree");
									}}
								>
									<div className="agree_checkbox_img_box">
										<img
											src="img/info/agree_checkbox.png"
											alt="checked"
											className="info_img_agree_checkbox"
										></img>
									</div>
									<div className="agree_checked_img_box">
										<img
											src="img/info/checked.png"
											alt="checked"
											className={`info_img_agree_checked ${viewTotalAgree}`}
										></img>
									</div>
								</div>
								<div
									className="label_agree_see pointer"
									onClick={() => togglePopup("")}
								>
									[약관보기]
								</div>
							</div>
							<div className="left_content warning_box">
								<div className="warning_text">
									<img
										src="img/info/warning_text.png"
										alt="checked"
										className={`info_img_warning_text`}
									></img>
								</div>
							</div>
						</div>
					</div>
					<div className="center_content fadein">
						<img
							src={`img/info/register${viewRegisterEvent}.png`}
							alt="register"
							className={`info_img_register pointer`}
							onClick={submit}
						></img>
					</div>
				</div>
				<div className={`info_popup ${viewPopup}`}>
					<div className="center_content">
						<img
							src={`img/info/popup_bg.png`}
							alt="pop up"
							className={`info_img_popup`}
						></img>
					</div>
					<div className="left_content">
						<div
							className="pointer"
							onClick={() => {
								select("useAgree", "Y");
							}}
						>
							<div>
								<img
									src={`img/info/checked.png`}
									alt="pop up checked"
									className={`info_img_popup_privacy_use_agree_checked ${viewUseAgree}`}
								></img>
							</div>
							<div>
								<img
									src={`img/info/privacy_agree.png`}
									alt="pop up"
									className={`info_img_popup_privacy_use_agree`}
								></img>
							</div>
						</div>
						<div
							className="pointer"
							onClick={() => {
								select("useAgree", "N");
							}}
						>
							<div>
								<img
									src={`img/info/checked.png`}
									alt="pop up checked"
									className={`info_img_popup_privacy_use_disagree_checked ${viewUseDisagree}`}
								></img>
							</div>
							<div>
								<img
									src={`img/info/disagree.png`}
									alt="pop up"
									className={`info_img_popup_privacy_use_disagree`}
								></img>
							</div>
						</div>
					</div>
					<div className="left_content">
						<div
							className="pointer"
							onClick={() => {
								select("marketingAgree", "Y");
							}}
						>
							<div>
								<img
									src={`img/info/checked.png`}
									alt="pop up checked"
									className={`info_img_popup_marketing_agree_checked ${viewMarketingAgree}`}
								></img>
							</div>
							<div>
								<img
									src={`img/info/marketing_agree.png`}
									alt="pop up"
									className={`info_img_popup_marketing_agree`}
								></img>
							</div>
						</div>
						<div
							className="pointer"
							onClick={() => {
								select("marketingAgree", "N");
							}}
						>
							<div>
								<img
									src={`img/info/checked.png`}
									alt="pop up checked"
									className={`info_img_popup_marketing_disagree_checked ${viewMarketingDisagree}`}
								></img>
							</div>
							<div>
								<img
									src={`img/info/disagree.png`}
									alt="pop up"
									className={`info_img_popup_marketing_disagree`}
								></img>
							</div>
						</div>
					</div>
					<div className="left_content">
						<div
							className="pointer"
							onClick={() => {
								select("offerAgree", "Y");
							}}
						>
							<div>
								<img
									src={`img/info/checked.png`}
									alt="pop up checked"
									className={`info_img_popup_privacy_offer_agree_checked ${viewOfferAgree}`}
								></img>
							</div>
							<div>
								<img
									src={`img/info/privacy_agree.png`}
									alt="pop up agree"
									className={`info_img_popup_privacy_offer_agree`}
								></img>
							</div>
						</div>
						<div
							className="pointer"
							onClick={() => {
								select("offerAgree", "N");
							}}
						>
							<div>
								<img
									src={`img/info/checked.png`}
									alt="pop up checked"
									className={`info_img_popup_privacy_offer_disagree_checked ${viewOfferDisagree}`}
								></img>
							</div>
							<div>
								<img
									src={`img/info/disagree.png`}
									alt="pop up disagree"
									className={`info_img_popup_privacy_offer_disagree`}
								></img>
							</div>
						</div>
						<div className="pointer">
							<a
								href="https://lghbflagshipstorage161855-prod.s3.ap-northeast-2.amazonaws.com/public/homebrewhaus_privacy_policy.pdf"
								target="_blank"
							>
								<img
									src={`img/info/privacy_policy_link.png`}
									alt="pop up disagree"
									className={`info_img_popup_privacy_policy_link`}
								></img>
							</a>
						</div>
					</div>
					<div className="center_content">
						<img
							src={`img/info/close_btn.png`}
							alt="pop up close"
							className={`info_img_popup_close pointer`}
							onClick={() => togglePopup("hidden")}
						></img>
					</div>
				</div>
			</div>
		</>
	);
};

export default Info;

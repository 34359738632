import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../css/default.css";

const Start = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function next() {
		navigate("/age");
	}

	return (
		<>
			<div className="container">
				<div className="start">
					<div>
						<img
							src="img/main/description.png"
							alt="description"
							className="img_description fadein"
						></img>
					</div>
					<div className="start_text_start fadein" onClick={next}>
						<div className="center_content">
							<img
								src="img/main/start_button.png"
								alt="HOMEBREW HAUS"
								className="pointer start_button"
							></img>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Start;

import React from "react";
import { Routes, Route } from "react-router-dom";

import First from "./js/First";
import Age from "./js/Age";
import Info from "./js/Info";
import Start from "./js/Start";
import Question1 from "./js/Question1";
import Question2 from "./js/Question2";
import Question3 from "./js/Question3";
import Question4 from "./js/Question4";
import Question5 from "./js/Question5";
import Question6 from "./js/Question6";
import Question7 from "./js/Question7";
import Question8 from "./js/Question8";
import Analysis from "./js/Analysis";
import AmericanWheatBeer from "./js/result/AmericanWheatBeer";
import BavarianWeissbier from "./js/result/BavarianWeissbier";
import CaliforniaCommon from "./js/result/CaliforniaCommon";
import CitrusAle from "./js/result/CitrusAle";
import DancingLager from "./js/result/DancingLager";
import DarkAle from "./js/result/DarkAle";
import IndiaPaleAle from "./js/result/IndiaPaleAle";
import PaleAle from "./js/result/PaleAle";
import Pilsner from "./js/result/Pilsner";
import RedAle from "./js/result/RedAle";
import SessionIpa from "./js/result/SessionIpa";
import SopoongLager from "./js/result/SopoongLager";
import SpicyIpa from "./js/result/SpicyIpa";
import Stout from "./js/result/Stout";
import SummerAle from "./js/result/SummerAle";
import Wheat from "./js/result/Wheat";
import Loading from "./js/Loading";
import Admin from "./js/admin/Admin";
import Qr from "./js/Qr";

const App = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<First />}></Route>
				<Route path="/start" element={<Start />}></Route>
				<Route path="/age" element={<Age />}></Route>
				<Route path="/info" element={<Info />}></Route>
				<Route path="/loading" element={<Loading />}></Route>
				<Route path="/qr/:id" element={<Qr />}></Route>
				<Route path="/q1" element={<Question1 />}></Route>
				<Route path="/q2" element={<Question2 />}></Route>
				<Route path="/q3" element={<Question3 />}></Route>
				<Route path="/q4" element={<Question4 />}></Route>
				<Route path="/q5" element={<Question5 />}></Route>
				<Route path="/q6" element={<Question6 />}></Route>
				<Route path="/q7" element={<Question7 />}></Route>
				<Route path="/q8" element={<Question8 />}></Route>
				<Route path="/analysis" element={<Analysis />}></Route>
				<Route path="/awb" element={<AmericanWheatBeer />}></Route>
				<Route path="/baw" element={<BavarianWeissbier />}></Route>
				<Route path="/cac" element={<CaliforniaCommon />}></Route>
				<Route path="/cia" element={<CitrusAle />}></Route>
				<Route path="/dal" element={<DancingLager />}></Route>
				<Route path="/daa" element={<DarkAle />}></Route>
				<Route path="/ipa" element={<IndiaPaleAle />}></Route>
				<Route path="/paa" element={<PaleAle />}></Route>
				<Route path="/pil" element={<Pilsner />}></Route>
				<Route path="/rea" element={<RedAle />}></Route>
				<Route path="/sei" element={<SessionIpa />}></Route>
				<Route path="/sol" element={<SopoongLager />}></Route>
				<Route path="/spi" element={<SpicyIpa />}></Route>
				<Route path="/sto" element={<Stout />}></Route>
				<Route path="/sua" element={<SummerAle />}></Route>
				<Route path="/whe" element={<Wheat />}></Route>
				<Route path="/admin" element={<Admin />}></Route>
				{/* <Route path="/mbtitest" element={<MbtiTest />}></Route> */}
			</Routes>
		</>
	);
};

export default App;

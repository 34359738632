import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import QRCode from "react-qr-code";

import "../css/default.css";

const Qr = () => {
	const { id } = useParams();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className="container">
				<div className="qr">
					<div className="card_area">
						<img
							className="card_template"
							src="/img/card/template.png"
							alt="template"
						></img>
						<QRCode
							className="qrcode"
							// size={200}
							style={{
								position: "absolute",
								height: "auto",
								maxWidth: "100%",
								width: "45%",
								zIndex: 10,
								marginBottom: "9.5%",
							}}
							value={id}
							viewBox={`0 0 256 256`}
							bgColor={`transparent`}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Qr;

import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import * as subscribtions from "../../graphql/subscriptions";

import React, { useEffect, useState, useRef } from "react";

import { CSVLink, CSVDownload } from "react-csv";

import {
	AmplifyAuthenticator,
	AmplifySignIn,
	AmplifySignOut,
	AmplifyContainer,
} from "@aws-amplify/ui-react";

import "../../css/admin.css";

const headers = [
	{ label: "등록시간", key: "createdAt" },
	{ label: "이름", key: "name" },
	{ label: "휴대폰", key: "phoneNumber" },
	{ label: "성별", key: "gender" },
	{ label: "연령대", key: "age" },
	{ label: "추천", key: "mbti" },
	{ label: "선택1", key: "material1" },
	{ label: "선택2", key: "material2" },
	{ label: "선택3", key: "material3" },
	{ label: "선택4", key: "material4" },
	{ label: "마케팅동의", key: "marketing" },
];

const csvHeaders = [
	{ label: "요일", key: "week" },
	{ label: "날짜", key: "day" },
	{ label: "등록시간", key: "time" },
	{ label: "이름", key: "name" },
	{ label: "휴대폰", key: "phoneNumber" },
	{ label: "성별", key: "gender" },
	{ label: "연령대", key: "age" },
	{ label: "추천", key: "mbti" },
	{ label: "선택1", key: "material1" },
	{ label: "선택2", key: "material2" },
	{ label: "선택3", key: "material3" },
	{ label: "선택4", key: "material4" },
	{ label: "마케팅동의", key: "marketing" },
];

const Admin = (props) => {
	const [customers, setCustomers] = useState([]);
	const [csvDatas, setCsvDatas] = useState([]);
	const [newCustomer, setNewCustomer] = useState(null);
	const [updatedCustomer, setUpdatedCustomer] = useState(null);

	const [todayNum, setTodayNum] = useState(0);
	const [totalNum, setTotalNum] = useState(0);

	const csvLink = useRef();

	async function fetchCustomers() {
		let listResponse = await API.graphql({
			query: queries.customersByCreatedAt,
			variables: {
				type: "Customer",
				sortDirection: "DESC",
			},
		});

		let fetchedCustomers = listResponse.data.customersByCreatedAt.items.map(
			(customer) => ({
				...customer,
				createdAt: new Date(customer.createdAt),
			}),
		);
		let nextToken = listResponse.data.customersByCreatedAt.nextToken;

		while (nextToken) {
			listResponse = await API.graphql({
				query: queries.customersByCreatedAt,
				variables: {
					type: "Customer",
					sortDirection: "DESC",
					nextToken: nextToken,
				},
			});

			fetchedCustomers = [
				...fetchedCustomers,
				...listResponse.data.customersByCreatedAt.items.map(
					(customer) => ({
						...customer,
						createdAt: new Date(customer.createdAt),
					}),
				),
			];
			nextToken = listResponse.data.customersByCreatedAt.nextToken;
		}

		const yesterday =
			String(new Date().getFullYear()) +
			"-" +
			String(new Date().getMonth() + 1) +
			"-" +
			String(new Date().getDate());

		const tn = fetchedCustomers.filter(
			(customer) =>
				customer.createdAt > new Date(`${yesterday} 00:00:00`),
		);

		setCustomers(fetchedCustomers);
		setTodayNum(tn.length);
		setTotalNum(fetchedCustomers.length);
	}

	async function fetchDownloadList() {
		let fetchedCustomers = await API.graphql({
			query: queries.customersByCreatedAt,
			variables: {
				type: "Customer",
				sortDirection: "DESC",
			},
		});

		let resultList = [...fetchedCustomers.data.customersByCreatedAt.items];

		let nextToken = fetchedCustomers.data.customersByCreatedAt.nextToken;

		while (nextToken) {
			fetchedCustomers = await API.graphql({
				query: queries.customersByCreatedAt,
				variables: {
					type: "Customer",
					sortDirection: "DESC",
					nextToken: nextToken,
				},
			});

			resultList = [
				...resultList,
				...fetchedCustomers.data.customersByCreatedAt.items,
			];

			nextToken = fetchedCustomers.data.customersByCreatedAt.nextToken;
		}

		setCsvDatas(
			resultList.map((data) => ({
				...data,
				week: String(new Date(data.createdAt)).split(" ")[0],
				day:
					String(new Date(data.createdAt)).split(" ")[1] +
					" " +
					String(new Date(data.createdAt)).split(" ")[2] +
					" " +
					String(new Date(data.createdAt)).split(" ")[3],
				time: String(new Date(data.createdAt)).split(" ")[4],
				updatedAt: new Date(data.updatedAt),
			})),
		);
	}

	useEffect(() => {
		if (csvDatas.length !== 0) {
			csvLink.current.link.click();
		}
	}, [csvDatas]);

	useEffect(() => {
		document.body.style.overflow = "auto";

		fetchCustomers();

		const createSubscription = API.graphql(
			graphqlOperation(subscribtions.onCreateCustomer),
		).subscribe({
			next: ({ provider, value }) => {
				const newCustomer = value.data.onCreateCustomer;
				setNewCustomer(newCustomer);
			},
			error: (error) => console.warn(error),
		});

		const updateSubscription = API.graphql(
			graphqlOperation(subscribtions.onUpdateCustomer),
		).subscribe({
			next: ({ provider, value }) => {
				const updatedCustomer = value.data.onUpdateCustomer;
				setUpdatedCustomer(updatedCustomer);
			},
			error: (error) => console.warn(error),
		});
	}, []);

	useEffect(() => {
		if (newCustomer !== null) {
			setCustomers([
				{ ...newCustomer, createdAt: new Date(newCustomer.createdAt) },
				...customers,
			]);
		}
	}, [newCustomer]);

	useEffect(() => {
		if (updatedCustomer !== null) {
			for (let i = 0; i < customers.length; i++) {
				if (customers[i].id === updatedCustomer.id) {
					customers[i].mbti = updatedCustomer.mbti;
					customers[i].material1 = updatedCustomer.material1;
					customers[i].material2 = updatedCustomer.material2;
					customers[i].material3 = updatedCustomer.material3;
					customers[i].material4 = updatedCustomer.material4;
				}
			}
			setCustomers([...customers]);
		}
	}, [updatedCustomer]);

	return (
		<AmplifyContainer>
			<AmplifyAuthenticator>
				<AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
				<div className="admin">
					<h1>MYBL ADMIN</h1>
					<h2 className="today_num">오늘 방문객 수: {todayNum}</h2>
					<h2 className="total_num">총 방문객 수: {totalNum}</h2>
					<div className="csv_link pointer">
						<button className="pointer" onClick={fetchDownloadList}>
							전체 방문자 목록 다운로드
						</button>
						<CSVLink
							ref={csvLink}
							data={csvDatas}
							headers={[
								...csvHeaders,
								{ label: "qrcode", key: "id" },
							]}
							filename={`전체 방문자 목록_${new Date().getFullYear()}_${
								new Date().getMonth() + 1
							}_${new Date().getDate()}`}
						></CSVLink>
					</div>
					<table>
						<thead>
							<tr>
								{headers.map((col) => (
									<th key={col.key}>{col.label}</th>
								))}
								<th key="delete_btn"></th>
							</tr>
						</thead>
						<tbody>
							{customers.map((row) => (
								<tr key={row.id}>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
										}}
									>
										{`${row.createdAt.getFullYear()}/${
											row.createdAt.getMonth() + 1
										}/${row.createdAt.getDate()}`}{" "}
										<br></br>
										{`${row.createdAt.getHours()}:${new Date(
											row.createdAt,
										).getMinutes()}:${row.createdAt.getSeconds()}`}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.name}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											wordBreak: "break-all",
										}}
									>
										{row.phoneNumber}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.gender}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.age}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.mbti}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.material1}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.material2}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.material3}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.material4}
									</td>
									<td
										style={{
											textAlign: "center",
											width: "8vw",
											whiteSpace: "normal",
											wordWrap: "break-word",
											wordBreak: "break-all",
										}}
									>
										{row.marketing}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</AmplifyAuthenticator>
		</AmplifyContainer>
	);
};

export default Admin;

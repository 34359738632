import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Storage, API } from "aws-amplify";

import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";

import { v4 as uuid } from "uuid";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import queryString from "query-string";
import crypto from "crypto-js";

import "../css/default.css";

const Loading = () => {
	const navigate = useNavigate();
	const location = useLocation();

	// const cardRef = useRef();

	const [name, setName] = useState(null);

	const [loadingTextNameStyle, setLoadingTextNameStyle] = useState({
		fontSize: "22.93px",
	});

	const [key, setKey] = useState("init");
	const [cardView, setCardView] = useState("none");

	const [viewCapsule1, setViewCapsule1] = useState("hidden");
	const [viewCapsule2, setViewCapsule2] = useState("hidden");
	const [viewCapsule3, setViewCapsule3] = useState("hidden");
	const [viewCapsule4, setViewCapsule4] = useState("hidden");

	useEffect(() => {
		const qs = queryString.parse(location.search);

		if (!qs.n || !qs.g || !qs.a || !qs.p) {
			alert("잘못된 접근입니다.");
			navigate("/");
		}

		setName(qs.n);

		if (window.innerWidth > 1024) {
			if (qs.n.length === 6) {
				setLoadingTextNameStyle({
					fontSize: "16.8px",
					marginTop: "4px",
				});
			} else if (qs.n.length === 5) {
				setLoadingTextNameStyle({
					fontSize: "20.1px",
					marginTop: "2px",
				});
			}
		} else if (window.innerWidth <= 1024 && window.innerWidth > 480) {
			if (qs.n.length === 6) {
				setLoadingTextNameStyle({
					fontSize: "calc(16.8/390*100vw)",
					marginTop: "calc(4/390*100vw)",
				});
			} else if (qs.n.length === 5) {
				setLoadingTextNameStyle({
					fontSize: "calc(20.1/390*100vw)",
					marginTop: "calc(2/390*100vw)",
				});
			} else {
				setLoadingTextNameStyle({
					fontSize: "calc(22.93/390*100vw)",
				});
			}
		} else {
			if (qs.n.length === 6) {
				setLoadingTextNameStyle({
					fontSize: "calc(16.8/670*var(--ih, 100vh))",
					marginTop: "calc(4/670*var(--ih, 100vh))",
				});
			} else if (qs.n.length === 5) {
				setLoadingTextNameStyle({
					fontSize: "calc(20.1/670*var(--ih, 100vh))",
					marginTop: "calc(2/670*var(--ih, 100vh))",
				});
			} else {
				setLoadingTextNameStyle({
					fontSize: "calc(22.93/670*var(--ih, 100vh))",
				});
			}
		}

		setTimeout(() => {
			setViewCapsule1("");
		}, 600);

		setTimeout(() => {
			setViewCapsule2("");
		}, 1200);

		setTimeout(() => {
			setViewCapsule3("");
			let uniqueID = uuid();
			setKey(uniqueID);
		}, 1800);

		setTimeout(() => {
			setViewCapsule4("");
		}, 2400);
	}, []);

	useEffect(() => {
		if (key !== "init") {
			setCardView("flex");
		}
	}, [key]);

	useEffect(() => {
		if (key !== "init" && cardView === "flex") {
			uploadCard(key);
		}
	}, [cardView]);

	async function uploadCard(key) {
		const qs = queryString.parse(location.search);

		let decData = crypto.enc.Base64.parse(qs.p).toString(crypto.enc.Utf8);
		let bytes = crypto.AES.decrypt(decData, "secret 12345").toString(
			crypto.enc.Utf8,
		);
		const pn = bytes.toString(crypto.enc.Utf8);

		const formState = {
			id: key,
			type: "Customer",
			name: qs.n,
			gender: qs.g,
			age: qs.a,
			phoneNumber: pn.replaceAll(`"`, ""),
			marketing: qs.m,
			number: 0,
			mbti: "none",
			material1: 0,
			material2: 0,
			material3: 0,
			material4: 0,
		};

		const storageKey = formState.phoneNumber.replace(/-/g, "") + ".jpeg";

		try {
			let listResponse = await API.graphql({
				query: queries.customersByCreatedAt,
				variables: {
					type: "Customer",
					sortDirection: "DESC",
				},
			});

			let fetchedCustomers = listResponse.data.customersByCreatedAt.items;
			let nextToken = listResponse.data.customersByCreatedAt.nextToken;

			while (nextToken) {
				listResponse = await API.graphql({
					query: queries.customersByCreatedAt,
					variables: {
						type: "Customer",
						sortDirection: "DESC",
						nextToken: nextToken,
					},
				});

				fetchedCustomers = [
					...fetchedCustomers,
					...listResponse.data.customersByCreatedAt.items,
				];
				nextToken = listResponse.data.customersByCreatedAt.nextToken;
			}

			formState.number = fetchedCustomers.length + 1;

			await API.graphql({
				query: mutations.createCustomer,
				variables: { input: formState },
			});
			navigate(`/q1?i=${formState.id}&n=${formState.name}`);
		} catch (error) {
			alert("오류가 발생하였습니다. 다시 시도해주세요.");
			console.log("error creating data:", error);
			return;
		}
	}

	return (
		<>
			<div className="container">
				<div className="loading">
					<div className="center_content fadein">
						<img
							src="img/loading/haus.png"
							alt="haus"
							className="loading_img_haus"
						></img>
					</div>
					<div className="center_content fadein">
						<div>
							<div className="center_content">
								<div>
									<p className="loading_text_line1">
										취향 테스트가 곧 시작됩니다.
									</p>
									<div className="center_content loading_line2">
										<div className="loading_name_box">
											<div className="center_content">
												<span
													className="loading_text_name"
													style={loadingTextNameStyle}
												>
													{name}
												</span>
											</div>
											<img
												src="img/loading/line.png"
												alt="line"
												className="loading_img_line"
											></img>
										</div>
										<div className="loading_text_line2">
											님의 가전 취향은?
										</div>
									</div>
								</div>
							</div>
							<div className="center_content">
								<div>
									<img
										src="img/loading/capsule.png"
										alt="loading1"
										className={`loading_img_capsule ${viewCapsule1}`}
									></img>
									<img
										src="img/loading/capsule.png"
										alt="loading2"
										className={`loading_img_capsule ${viewCapsule2}`}
									></img>
									<img
										src="img/loading/capsule.png"
										alt="loading3"
										className={`loading_img_capsule ${viewCapsule3}`}
									></img>
									<img
										src="img/loading/capsule.png"
										alt="loading4"
										className={`loading_img_capsule ${viewCapsule4}`}
									></img>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Loading;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../css/default.css";

const First = () => {
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	function next() {
		navigate("/start");
	}

	return (
		<>
			<div className="container" onClick={next}>
				<div className="first">
					<header className="center_content fadein">
						<img
							src="img/main/logo.png"
							alt="HOMEBREW HAUS"
							className="header_text_logo"
						></img>
					</header>
					<div className="center_content">
						<img
							src="img/main/haus.png"
							alt="HOMEBREW HAUS"
							className="img_haus fadein"
						></img>
					</div>
					<div className="first_text_find fadein">
						<div className="center_content">
							<img
								src="img/main/first_button.png"
								alt="HOMEBREW HAUS"
								className="pointer first_button"
							></img>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default First;

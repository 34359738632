/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      type
      name
      gender
      age
      phoneNumber
      number
      marketing
      mbti
      material1
      material2
      material3
      material4
      label
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        gender
        age
        phoneNumber
        number
        marketing
        mbti
        material1
        material2
        material3
        material4
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customersByCreatedAt = /* GraphQL */ `
  query CustomersByCreatedAt(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customersByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        gender
        age
        phoneNumber
        number
        marketing
        mbti
        material1
        material2
        material3
        material4
        label
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

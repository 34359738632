import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../css/default.css";

const Age = () => {
	const navigate = useNavigate();
	const [viewY, setViewY] = useState("hidden");
	const [viewN, setViewN] = useState("hidden");

	function select(value) {
		if (value === "Y") {
			setViewY("");
			setTimeout(() => {
				setViewY("hidden");
				navigate("/info");
			}, 100);
		} else {
			setViewN("");
			setTimeout(() => {
				setViewN("hidden");
				navigate("/");
			}, 100);
		}
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className="container">
				<div className="age">
					<div className="center_content fadein">
						<img
							src="img/age/haus.png"
							alt="haus"
							className="age_img_haus"
						></img>
					</div>
					<div className="center_content fadein">
						<p className="text_center age_text_question">
							만 19세 이상<br></br>음주 가능한 연령입니까?
						</p>
					</div>
					<div className="center_content radio_content fadein">
						<span className="radio_box">
							<img
								src="img/age/check_box.png"
								alt="check box"
								className="age_img_check_box_yes"
								onClick={() => select("Y")}
							></img>
							<img
								src="img/age/checked.png"
								alt="checked"
								className={`age_img_checked_yes ${viewY}`}
							></img>
							<span
								className="age_text_yes pointer"
								onClick={() => select("Y")}
							>
								YES
							</span>
						</span>
						<span className="radio_box">
							<img
								src="img/age/check_box.png"
								alt="check box"
								className="age_img_check_box_no"
								onClick={() => select("N")}
							></img>
							<img
								src="img/age/checked.png"
								alt="checked"
								className={`age_img_checked_no ${viewN}`}
							></img>
							<span
								className="age_text_no pointer"
								onClick={() => select("N")}
							>
								NO
							</span>
						</span>
					</div>
					<div className="center_content fadein">
						<img
							src="img/age/warning.png"
							alt="warning"
							className="age_img_warning"
						></img>
					</div>
				</div>
			</div>
		</>
	);
};

export default Age;
